import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useInitialRender } from '../hooks/useInitialRender'
import { FileUpload } from '../types/orgTypes'
import { useOrg } from './OrgContext'

const UploadContext = React.createContext<useUploadContextProps>({
  uploadFile: () => {},
  queue: [],
  next: [],
  percentage: [],
})

export function useUpload() {
  return useContext(UploadContext)
}

interface useUploadContextProps {
  uploadFile: Function
  queue: { id: string; chunk: number }[]
  next: { id: string; chunk: number }[]
  percentage: { id: string; percentage: number | string }[]
}

export function UploadProvider({ children }: { children: React.ReactNode }) {
  const [queue, setQueue] = useState<{ id: string; chunk: number }[]>([])
  const [next, setNext] = useState<{ id: string; chunk: number }[]>([])
  const [percentage, setPercentage] = useState<{ id: string; percentage: number | string }[]>([])
  const { orgFetch } = useOrg()

  useInitialRender(() => {
    ;(async () => {
      let uploads: FileUpload[] = []
      try {
        const res = await orgFetch<FileUpload[]>('/api/org/upload/all')
        const data = await res.json()
        if (res.ok) {
          uploads = data
        }
      } catch (err) {
        console.error(err)
      }
      uploads.forEach(async upload => {
        if (upload.status == 'ACTIVE') {
          try {
            if (upload.lastChunk == upload.totalChunks) {
              const res = await orgFetch(`/api/org/upload/finish?id=${upload.id}`)
              const data: any = await res.json()
              if (!data?.missing) {
                return
              }
            }
            toast.loading(`Trying to restart upload: \n${upload.name}`, { id: `upload-${upload.id}` })
            try {
              await getChunk(upload.id, upload.lastChunk)
            } catch (err) {
              return toast.error(`Unable to locate file for ${upload.name}\nPlease delete this upload and start again`, { id: `upload-${upload.id}` })
            }
            toast.success(`Continuing upload: \n${upload.name}`, { id: `upload-${upload.id}` })
            setQueue(prev => {
              return [
                ...prev,
                ...Array(upload.totalChunks - (upload.lastChunk - 4 > 0 ? upload.lastChunk - 4 : 0))
                  .fill(0)
                  .map((el, i) => {
                    return { id: upload.id, chunk: i + 1 + (upload.lastChunk - 4 > 0 ? upload.lastChunk - 4 : 0) }
                  }),
              ]
            })
          } catch (err) {
            console.error(err)
          }
        } else {
          await deleteFile(upload.id)
        }
      })
    })()
  }, [])

  // effect that makes the upload requests based on the next queue
  useEffect(() => {
    if (next.length == 0) {
      return
    }
    ;(async () => {
      const finishedUpload = await Promise.all(
        next.map(async n => {
          if (!n?.id || !n?.chunk) {
            return
          }
          try {
            const res = await orgFetch<{ url: string; isLast: boolean; percentage: number; id: string }>(
              `/api/org/upload/url?id=${n.id}&chunk=${n.chunk}`
            )
            const uploadUrl = await res.json()
            uploadUrl.id = n.id
            const chunk = (await getChunk(n.id, n.chunk)) as string
            await fetch(uploadUrl.url, {
              method: 'PUT',
              body: chunk,
            })
            if (!queue.find(el => el.id == n.id)) {
              const res = await orgFetch(`/api/org/upload/finish?id=${n.id}`)
              const data: any = await res.json()
              if (!data?.missing) {
                setPercentage(prev => {
                  return [...updatePercentage(prev, n.id, 100)]
                })
                return
              }
              setQueue(prev => {
                return [
                  ...prev,
                  ...data.missing.map((upload: any) => {
                    return { id: n.id, chunk: upload }
                  }),
                ]
              })
            }
            return uploadUrl
          } catch (err) {
            console.error(err)
          }
        })
      )
      setPercentage(prev => {
        return [...updatePercentage(prev, finishedUpload[0]?.id || '', finishedUpload[0]?.percentage || 0)]
      })

      nextChunks()
    })()
  }, [JSON.stringify(next)])

  // effect that starts the initial queue when a new upload starts
  useEffect(() => {
    if (queue.length != 0 && next.length == 0) {
      nextChunks()
    }
  }, [JSON.stringify(queue)])

  // function that populates the "next" queue based on the queue
  function nextChunks(max = 4) {
    const amount = queue.length > max - 1 ? max - 1 : queue.length
    setNext([
      ...Array(amount + 1)
        .fill(0)
        .map((el, i) => queue[i]),
    ])
    setQueue(prev => {
      const temp = [...prev]
      temp.splice(0, amount + 1)
      return [...temp]
    })
  }

  // function that can be called with a file to start upload
  async function uploadFile(file: File, record: FileUpload) {
    console.log('u')
    setPercentage(prev => {
      return [...updatePercentage(prev, record.id, "preparing file - don't close this window")]
    })
    await putFile(file, record.id)
    setQueue(prev => {
      return [
        ...prev,
        ...Array(record.totalChunks)
          .fill(0)
          .map((el, i) => {
            return { id: record.id, chunk: i + 1 }
          }),
      ]
    })
    setPercentage(prev => {
      return [...updatePercentage(prev, record.id, 0)]
    })
  }

  const value: useUploadContextProps = {
    uploadFile,
    queue,
    next,
    percentage,
  }

  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>
}

async function putFile(file: File, id: string) {
  return new Promise((resolve, reject) => {
    let db: any
    const request = indexedDB.open('files', 1.0)

    request.onerror = () => {
      reject('Unable to connect to database')
    }

    request.onsuccess = e => {
      // @ts-ignore
      db = e.target.result
      const trans = db.transaction(['files'], 'readwrite')
      const addReq = trans.objectStore('files').add(file, id)

      addReq.onerror = () => {
        reject('Unable to add data to database')
      }

      trans.oncomplete = () => {
        resolve(file)
      }
    }

    request.onupgradeneeded = function (e) {
      // @ts-ignore
      let db = e.target.result
      db.createObjectStore('files')
    }
  })
}

export async function deleteFile(id: string) {
  return new Promise((resolve, reject) => {
    let db: any
    const request = indexedDB.open('files', 1.0)

    request.onerror = () => {
      reject('Unable to connect to database')
    }

    request.onsuccess = e => {
      // @ts-ignore
      db = e.target.result
      const trans = db.transaction(['files'], 'readwrite')
      const addReq = trans.objectStore('files').delete(id)

      addReq.onerror = () => {
        resolve("file didn't exist anymore")
      }

      trans.oncomplete = () => {
        resolve(id)
      }
    }

    request.onupgradeneeded = function (e) {
      // @ts-ignore
      let db = e.target.result
      db.createObjectStore('files')
    }
  })
}

async function getChunk(id: string, chunkNumber: number) {
  return new Promise((resolve, reject) => {
    try {
      let db: any
      const request = indexedDB.open('files', 1.0)
      const chunkSize = 5 * 1024 * 1024

      request.onerror = () => {
        reject('Unable to connect to database')
      }

      request.onsuccess = e => {
        // @ts-ignore
        db = e.target.result
        const trans = db.transaction(['files'], 'readwrite')
        const req = trans.objectStore('files').get(id)

        req.onerror = () => {
          reject('Unable to add data to database')
        }

        trans.oncomplete = () => {
          const file = req.result
          if (!file) {
            return reject('File not found')
          }
          const chunk = file.slice((chunkNumber - 1) * chunkSize, chunkNumber * chunkSize)
          resolve(chunk)
        }
      }

      request.onupgradeneeded = function (e) {
        // @ts-ignore
        let db = e.target.result
        db.createObjectStore('files')
      }
    } catch (err) {
      reject(err)
    }
  })
}

function updatePercentage(prev: any[], id: string, percentage: number | string) {
  const temp = [...prev]
  console.log(temp)
  console.log(id)
  if (
    !temp.find(p => {
      return p.id == id
    })
  ) {
    console.log('not found')
    temp.push({ id: id, percentage: percentage })
  } else {
    temp[temp.findIndex(el => el.id == id)].percentage = percentage
  }
  return temp
}
