import { useState, useEffect } from 'react'

export function useScreen() {
  const [screenSize, setScreenSize] = useState(typeof window != 'undefined' ? [window.innerWidth, window.innerHeight] : [0, 0])

  useEffect(() => {
    if (typeof window === 'undefined') return
    window.addEventListener('resize', (e: any) => {
      setScreenSize([window.innerWidth, window.innerHeight])
    })
    return () => {
      window.removeEventListener('resize', (e: any) => {})
    }
  }, [])

  return { width: screenSize[0], height: screenSize[1] }
}
