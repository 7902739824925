import React, { useContext, useEffect, useState } from 'react'
import { useStoredFetch } from '../hooks'
import { useLocalStore } from '../hooks/useLocalStore'
import { useAuth } from './AuthContex'

const ThemeContext = React.createContext<useThemeProps>({
  theme: 'dark',
  setTheme: () => {},
  colorScheme: 'dark',
  menuOpen: true,
  setMenuOpen: () => {},
  accentColor: '',
  setAccentColor: () => {},
  getColors: () => [],
})

export function useTheme() {
  return useContext(ThemeContext)
}

type setState<T> = React.Dispatch<React.SetStateAction<T>>
interface useThemeProps {
  theme: 'dark' | 'light' | 'system'
  setTheme: (theme: 'dark' | 'light' | 'system') => any
  colorScheme: 'dark' | 'light'
  menuOpen: boolean
  setMenuOpen: setState<boolean>
  accentColor: string
  setAccentColor: (color: string) => any
  getColors: (amount: number) => string[]
}

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const { apiFetch } = useAuth()
  const { data: fullTheme } = useStoredFetch<{ colorScheme: 'light' | 'dark' | 'system'; accentColor: string }>('themeSettings', async signal => {
    const res = await apiFetch('/account/theme', { signal: signal })
    const data = await res.json()
    if (res.ok) {
      return data
    }
    console.log(data)
  })
  const [theme, setTheme] = useState<'dark' | 'light' | 'system'>(fullTheme?.colorScheme || 'system')
  const [colorScheme, setColorScheme] = useState<'dark' | 'light'>('dark')
  const [accentColor, setAccentColor] = useState<string>(fullTheme?.accentColor || '#EF1E6E')
  const [menuOpen, setMenuOpen] = useLocalStore<boolean>('menuOpen', true)

  async function postNewTheme(theme: any) {
    try {
      await apiFetch('/account/theme', {
        method: 'POST',
        body: JSON.stringify(theme),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setColorScheme(
      theme == 'dark' ? 'dark' : theme == 'light' ? 'light' : window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    )
  }, [theme])

  useEffect(() => {
    setTheme(fullTheme?.colorScheme || 'system')
    setAccentColor(fullTheme?.accentColor || '#EF1E6E')
    function colorSchemeHandler(e: MediaQueryListEvent) {
      if (theme === 'system') setColorScheme(e.matches ? 'dark' : 'light')
    }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', colorSchemeHandler)
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', colorSchemeHandler)
    }
  }, [fullTheme, fullTheme?.accentColor, fullTheme?.colorScheme])

  function updateAccentColor(accentColor: string) {
    setAccentColor(accentColor)
    localStorage.setItem(
      'themeSettings',
      JSON.stringify({
        colorScheme: theme,
        accentColor: accentColor,
      })
    )
    postNewTheme({
      colorScheme: theme,
      accentColor: accentColor,
    })
  }

  function updateTheme(theme: 'dark' | 'light' | 'system') {
    setTheme(theme)
    localStorage.setItem(
      'themeSettings',
      JSON.stringify({
        colorScheme: theme,
        accentColor: accentColor,
      })
    )
    postNewTheme({
      colorScheme: theme,
      accentColor: accentColor,
    })
  }

  function getColors(amount: number) {
    const accentColor = getComputedStyle(document.documentElement).getPropertyValue('--accent-clr')
    const colors = ['#EF1E6E', '#FDBA2C', '#FF7F00', '#BF80FF', '#576AFF', '#3BBBFF', '#12D18F', '#4DCF48', '#0fFF00', '#FF2700', '#FF52EB']
    colors.splice(colors.indexOf(accentColor), 1)
    const finalArray = [accentColor]
    const length = amount > colors.length + 1 ? colors.length : amount - 1
    for (let i = 0; i < length; i++) {
      finalArray.push(colors[i])
    }
    return finalArray
  }

  const value: useThemeProps = {
    theme,
    setTheme: updateTheme,
    colorScheme,
    menuOpen,
    setMenuOpen,
    accentColor,
    setAccentColor: updateAccentColor,
    getColors: getColors,
  }

  const breakpoints = {
    '--mobileSuperSmall': '400px',
    '--mobileSmall': '550px',
    '--mobileMedium': '600px',
    '--mobile': '768px',
    '--tablet': '992px',
    '--tabletBig': '1050px',
    '--desktopSmall': '1150px',
    '--desktop': '1200px',
    '--deskTopLarge': '1400px',
  }

  const colors = {
    '--accent-clr': accentColor,
    '--clr-success': '#10D064',
    '--clr-alert': '#ff7f00',
    '--clr-error': '#f52727',
    '--primary-bg-clr': `var(--primary-bg-clr-${colorScheme})`,
    '--secondary-bg-clr': `var(--secondary-bg-clr-${colorScheme})`,
    '--primary-text-clr': `var(--primary-text-clr-${colorScheme})`,
    '--secondary-text-clr': `var(--secondary-text-clr-${colorScheme})`,
    '--primary-bg-clr-faded': `var(--primary-bg-clr-faded-${colorScheme})`,
    '--onaccent-text-clr': `white`,
    '--primary-bg-clr-dark': '#07071d',
    '--secondary-bg-clr-dark': '#1f1f3d',
    '--primary-text-clr-dark': 'white',
    '--secondary-text-clr-dark': '#8688a7',
    '--primary-bg-clr-faded-dark': 'rgba(7, 7, 29, .7)',
    '--primary-bg-clr-light': '#ececf0',
    '--secondary-bg-clr-light': 'white',
    '--primary-text-clr-light': 'black',
    '--secondary-text-clr-light': '#595a72',
    '--primary-bg-clr-faded-light': 'rgba(236, 236, 240, .7)',
  }

  const layout = {
    '--border-radius-small': '6px',
    '--border-radius-medium': '10px',
    '--border-radius-large': '15px',
  }

  useEffect(() => {
    Object.entries(
      [colors, layout, breakpoints].reduce((final, el: object) => {
        return Object.assign(final, el)
      }, {})
    ).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value as string)
    })

    const computedStyles = {
      '--accent-clr-soft': document.documentElement.style.getPropertyValue('--accent-clr'),
    }

    Object.entries(
      [computedStyles].reduce((final, el: object) => {
        return Object.assign(final, el)
      }, {})
    ).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value as string)
    })
  }, [colors])

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

enum COLORS {
  blackPri = '#07071D',
  blackSec = '#1F1F3D',

  textWhite = '#FDFDFF',
  textGrey = '#8688A7',
  textGreyBlack = '#595A72',
  textBlack = '#07071D',

  whitePri = '#ECECF0',
  whitePriDarker = '#D0D0E2',
  whiteSec = '#FDFDFF',

  primary = '#EF1E6E',
  primaryDark = '#C0195E',
  secondary = '#42469C',
  secondaryDark = '#22267C',

  error = '#f52727',

  positive = '#10D064',
  negative = '#E22649',

  accentDefault = '#EF1E6E',
  accentDefaultDark = '#A71652',
  accentYellow = '#FDBA2C',
  accentYellowDark = '#E59F03',
  accentOrange = '#FF7F00',
  accentOrangeDark = '#D5481A',
  accentRed = '#FF2700',
  accentRedDark = '#D11F00',
  accentPink = '#FF52EB',
  accentPinkDark = '#C700B0',
  accentPurple = '#BF80FF',
  accentPurpleDark = '#7000E0',
  accentDarkBlue = '#576AFF',
  accentDarkBlueDark = '#0F2BFF',
  accentLightBlue = '#3BBBFF',
  accentLightBlueDark = '#0077B8',
  accentTeal = '#12D18F',
  accentTealDark = '#097750',
  accentGreen = '#4DCF48',
  accentGreenDark = '#278523',
}
