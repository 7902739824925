import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTheme } from '../../contexts/ThemeContext'
import { IoIosArrowDown } from 'react-icons/io'
import { useAuth } from '../../contexts'
import { useOrg } from '../../contexts/OrgContext'
import { Organisation } from '../../types/orgTypes'

interface NavlinkProps {
  icon: React.ReactElement
  text: string
  event: string | Function
  active?: boolean
  fontSize?: string
  disabled?: boolean
}

export function Navlink({ event, icon, text, active, fontSize, disabled }: NavlinkProps) {
  const router = useRouter()
  const { menuOpen } = useTheme()

  function handleClick() {
    if (!disabled) {
      typeof event == 'string' ? router.push(event) : (event as Function)()
    }
  }

  return (
    <div className={`navlink default ${active && 'active'} ${disabled && 'disabled'}`} onClick={() => handleClick()}>
      {icon}
      <span style={{ fontSize: fontSize }} className={menuOpen ? 'opened' : 'closed'}>
        {text}
      </span>
    </div>
  )
}

interface DropdownNavlinkProps extends NavlinkProps {
  children?: React.ReactElement<NavlinkProps> | Array<React.ReactElement<NavlinkProps>>
  open?: boolean
}

export function DropdownNavlink({ event, icon, text, children, active, open, fontSize }: DropdownNavlinkProps) {
  const router = useRouter()
  const { menuOpen } = useTheme()

  return (
    <div className={`dropdown-navlink-wrapper ${open && 'open'}`}>
      <div
        className={`navlink default-dropdown ${active && 'active'}`}
        onClick={() => (typeof event == 'string' ? router.push(event) : (event as Function)())}
      >
        {icon}
        <span style={{ fontSize: fontSize }} className={menuOpen ? 'opened' : 'closed'}>
          {text}
        </span>
      </div>
      <div className={`dropdown-navlink-children ${menuOpen ? 'opened' : 'closed'}`}>{children}</div>
    </div>
  )
}

export function DropdownNavlinkItem({ event, icon, text, active, fontSize }: DropdownNavlinkProps) {
  const router = useRouter()
  const { menuOpen } = useTheme()

  return (
    <div
      className={`navlink default-dropdown item ${active ? 'active' : ''} ${menuOpen ? 'opened' : 'closed'}`}
      onClick={() => (typeof event == 'string' ? router.push(event) : (event as Function)())}
    >
      {icon}
      <span style={{ fontSize: fontSize }} className={menuOpen ? 'opened' : 'closed'}>
        {text}
      </span>
    </div>
  )
}

interface ProfileNavlinkProps {
  event: string | Function
  title: string
  subtitle: string
  active?: boolean
  profilePicture: string
}

export function ProfileNavlink({ event, title, subtitle, active, profilePicture }: ProfileNavlinkProps) {
  const router = useRouter()
  const { apiFetch } = useAuth()
  const {
    org: [currentOrg],
    orgId: [orgId, setOrgId],
  } = useOrg()
  const { menuOpen } = useTheme()
  const [orgs, setOrgs] = useState<Organisation[]>([])
  const [orgsDrop, setOrgsDrop] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const res = await apiFetch<Organisation[]>('/api/org/getallorgs')
        const orgs = await res.json()
        setOrgs(orgs)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  return (
    <div className="profile-org-wrapper">
      <div className={`nav-org ${menuOpen ? 'opened' : 'closed'} ${orgsDrop && 'dropdown'}`}>
        <div className="nav-org-current" onClick={() => setOrgsDrop(!orgsDrop)}>
          <p>{currentOrg?.name}</p>
          {orgs.length > 1 && <IoIosArrowDown className="icon" />}
        </div>
        <div style={{ overflow: 'auto' }}>
          {orgs
            .filter(org => org.id != currentOrg?.id)
            .map(org => {
              return (
                <div className="nav-org-sub" key={org.id} onClick={() => setOrgId(org.id)}>
                  {org.name}
                </div>
              )
            })}
        </div>
      </div>
      <div
        className={`navlink profile ${active && 'active'} ${menuOpen ? 'opened' : 'closed'}`}
        onClick={() => (typeof event == 'string' ? router.push(event) : (event as Function)())}
      >
        <img className={menuOpen ? 'opened' : 'closed'} src={profilePicture} alt="Profile Picture" />
        <div className={menuOpen ? 'opened' : 'closed'}>
          <p className="title">{title}</p>
          <p className="subtitle">{subtitle}</p>
        </div>
      </div>
    </div>
  )
}
