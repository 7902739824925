import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'
import { useAuth0, User } from '@auth0/auth0-react'
import { BarLoader } from 'react-spinners'
import { useQuery } from '@tanstack/react-query'
import { datadogRum } from '@datadog/browser-rum'

interface FetchResponse<T> extends Response {
  json: () => Promise<T>
}

const AuthContext = React.createContext<useAuthProps>({
  logout: () => {},
  login: () => {},
  signup: () => {},
  user: undefined,
  apiFetch: () => new Promise((resolve, reject) => {}),
})

export function useAuth() {
  return useContext(AuthContext)
}

interface Multifactor {
  multifactor: boolean
}

interface useAuthProps {
  logout: () => void
  login: (email: string) => any
  signup: (email: string) => any
  user: (User | Multifactor | any) | undefined
  apiFetch: <T>(path: string, init?: RequestInit | undefined) => Promise<FetchResponse<T>>
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const { logout: auth0Logout, loginWithRedirect, user: auth0User, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()

  const { data: user, isLoading: meLoading } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const res = await apiFetch('/me')
      const meObj = await res.json()
      if (res.ok) {
        return {
          ...auth0User,
          me: { ...meObj },
        }
      } else {
        throw new Error('something went wrong')
      }
    },
    enabled: !!(router.asPath !== '/login' && !isLoading && isAuthenticated),
    onError: () => logout(),
    staleTime: 0,
    retry: 2,
  })

  useEffect(() => {
    function handleKeyDown(event: any) {
      if (event.ctrlKey && event.altKey && event.key === 'x') {
        localStorage.removeItem('tekstai-cache')
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    if (auth0User) {
      datadogRum.setUser({
        id: auth0User.sub,
        name: auth0User.nickname,
        email: auth0User.email,
      })
    }
  }, [auth0User])

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      if (router.asPath !== '/login') {
        router.push('/login')
      }
    }
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    if (router.asPath === '/login') {
      if (user && auth0User) {
        router.push('/')
      }
    }
  }, [router.asPath, isAuthenticated, user])

  async function apiFetch<T = any>(path: string, init: RequestInit | undefined = {}): Promise<FetchResponse<T>> {
    const token = await getAccessTokenSilently()
    init.headers = {
      'Content-Type': 'application/json',
      ...init.headers,
      'Authorization': `Bearer: ${token}`,
    }
    const url = new URL(process.env.NEXT_PUBLIC_API_BASE_URL + path)
    const res: Promise<FetchResponse<T>> = fetch(`${url.toString()}`, {
      ...init,
    })
    return res
  }

  async function logout() {
    try {
      localStorage.clear()
      if (isAuthenticated) {
        auth0Logout({ returnTo: `${window.location.origin}/login` })
      } else {
        router.asPath !== '/login' && router.push('/login')
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function login(email: string) {
    try {
      await loginWithRedirect({ login_hint: email, prompt: 'login' })
    } catch (err) {
      console.log(err)
    }
  }

  async function signup(email: string = '') {
    try {
      await loginWithRedirect({ screen_hint: 'signup', login_hint: email })
    } catch (err) {
      console.log(err)
    }
  }

  const value: useAuthProps = {
    logout: logout,
    login: login,
    signup: signup,
    user: user,
    apiFetch: apiFetch,
  }

  return (
    <AuthContext.Provider value={value}>
      {router.asPath !== '/login' && (meLoading || isLoading || !isAuthenticated) ? <LoggingIn /> : children}
    </AuthContext.Provider>
  )
}

function LoggingIn() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'var(--primary-bg-clr)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
      }}
    >
      <BarLoader loading={true} color="#EF1E6E" speedMultiplier={1.5} cssOverride={{ borderRadius: '100px' }} />
      logging in
    </div>
  )
}
