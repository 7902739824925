import 'react-toggle/style.css'

import '../src/styles/globals.scss'
import '../src/styles/utility/form.scss'
import '../src/styles/utility/loading.scss'
import '../src/styles/utility/stats.scss'
import '../src/styles/navigation.scss'
import '../src/styles/main.scss'
import '../src/styles/pages/login.scss'
import '../src/styles/pages/profile.scss'
import '../src/styles/pages/settings.scss'
import '../src/styles/pages/dashboard.scss'
import '../src/styles/pages/feedback.scss'
import '../src/styles/utility/random.scss'
import '../src/styles/pages/upload.scss'
import '../src/styles/analytics.scss'
import '../src/styles/integrations.scss'
import 'react-tooltip/dist/react-tooltip.css'

import { ThemeProvider, AuthProvider, OrgProvider, DataProvider, UploadProvider, IntegrationProvider } from '../src/contexts'
import { NextPage } from 'next'
import { Menu } from '../src/components/menu/Menu'
import dynamic from 'next/dynamic'
import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import Modal from 'react-modal'
import { Toaster } from 'react-hot-toast'
import { datadogRum } from '@datadog/browser-rum'
import { useInitialRender } from '../src/hooks/useInitialRender'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 * 7,
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    },
  },
})

const persister = createSyncStoragePersister({
  storage: typeof window !== 'undefined' ? window.localStorage : null,
  key: 'tekstai-cache',
})

interface AppProps {
  Component: NextPage
}

const NoSSRWrapper = dynamic(
  () =>
    Promise.resolve(({ children }: { children: React.ReactNode }) => {
      return <>{children}</>
    }),
  { ssr: false }
)

Modal.setAppElement('body')

export default function App({ Component }: AppProps) {
  useInitialRender(() => {
    datadogRum.init({
      applicationId: 'be00ac33-6984-4ea4-979b-1078a5015475',
      clientToken: 'pubce53bcdc8a3b5a12c57ce679ddf04c6d',
      site: 'datadoghq.eu',
      service: 'frontend',
      env: 'prod',
      sampleRate: 100,
      sessionReplaySampleRate: 100,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingOrigins: [url => url.includes('tekst.ai') && !url.includes('auth')],
    })

    datadogRum.startSessionReplayRecording()
  }, [])

  return (
    <NoSSRWrapper>
      <Toaster />
      <Auth0Provider
        domain={String(process.env.NEXT_PUBLIC_AUTH0_BASE_URL)}
        clientId={String(process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID)}
        redirectUri={String(typeof window !== 'undefined' && window.location.origin)}
        audience="http://localhost:8000"
        scope="openid profile email"
      >
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
          <AuthProvider>
            <OrgProvider>
              <DataProvider>
                <IntegrationProvider>
                  <UploadProvider>
                    <ThemeProvider>
                      <BaseLayout>
                        <Component />
                      </BaseLayout>
                    </ThemeProvider>
                  </UploadProvider>
                </IntegrationProvider>
              </DataProvider>
            </OrgProvider>
          </AuthProvider>
          <ReactQueryDevtools panelPosition="right" position="bottom-right" initialIsOpen={false} />
        </PersistQueryClientProvider>
      </Auth0Provider>
    </NoSSRWrapper>
  )
}

interface BaseLayoutProps {
  children: React.ReactNode
}

function BaseLayout({ children }: BaseLayoutProps) {
  const exclude = ['/login']

  if (exclude.includes(window.location.pathname)) {
    return <>{children}</>
  }

  return (
    <div className="website-wrapper">
      <Menu />
      <div className="main-wrapper">{children}</div>
    </div>
  )
}
