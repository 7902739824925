import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { useMiniContext } from '../hooks'
import { Organisation, Role } from '../types/orgTypes'
import { useAuth } from './AuthContex'
import { BarLoader } from 'react-spinners'
import { useQuery } from '@tanstack/react-query'

type MiniContext<T = undefined> = [T | undefined, React.Dispatch<T | undefined>, boolean, React.Dispatch<boolean>]

interface FetchResponse<T> extends Response {
  json: () => Promise<T>
}

const OrgContext = React.createContext<useOrgProps>({
  orgId: [undefined, () => {}, true, () => {}],
  org: [undefined, () => {}, true, () => {}],
  role: 'READER',
  orgFetch: () => new Promise((resolve, reject) => {}),
})

export function useOrg() {
  return useContext(OrgContext)
}

interface useOrgProps {
  orgId: MiniContext<string>
  org: MiniContext<Organisation>
  role?: Role
  orgFetch: <T>(path: string, init?: RequestInit | undefined) => Promise<FetchResponse<T>>
}

export function OrgProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const { getAccessTokenSilently } = useAuth0()
  const { apiFetch, logout } = useAuth()
  const [loading, setLoading] = useState(true)
  const org = useMiniContext<Organisation>(undefined)
  const orgId = useMiniContext<string>(undefined)
  const [organisationId, setOrganisationId] = orgId
  const [currentOrg, setCurrentOrg] = org
  const [role, setRole] = useState<Role | undefined>()

  const { refetch, data } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['current-org'],
    queryFn: async () => {
      let org: Organisation
      if (!organisationId) {
        const res = await apiFetch<Organisation>('/api/org/current')
        const data = await res.json()
        if (!res.ok) {
          throw new Error('something went wrong')
        }
        org = data
      } else {
        const res = await apiFetch<Organisation>(`/api/org/current/${organisationId}`, {
          method: 'POST',
        })
        const data = await res.json()
        if (!res.ok) {
          throw new Error('something went wrong')
        }
        console.log(data)
        org = data
      }
      const res = await apiFetch<Role>(`/api/org/role?organisation=${org.id}`)
      const data = await res.json()
      if (!res.ok) {
        throw new Error('something went wrong')
      }
      return { org: org, role: data }
    },
    enabled: !!(router.asPath !== '/login'),
    onError: () => logout(),
    staleTime: 0,
    retry: 2,
  })

  useEffect(() => {
    refetch()
  }, [orgId[0]])

  useEffect(() => {
    if (data) {
      setOrganisationId(data.org.id)
      setCurrentOrg(data.org)
      setRole(data.role)
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (organisationId !== currentOrg?.id) {
      setLoading(true)
      refetch()
    }
  }, [organisationId])

  async function orgFetch<T = any>(path: string, init: RequestInit | undefined = {}): Promise<FetchResponse<T>> {
    const token = await getAccessTokenSilently()
    init.headers = {
      'Content-Type': 'application/json',
      ...init.headers,
      'Authorization': `Bearer: ${token}`,
    }
    const url = new URL(process.env.NEXT_PUBLIC_API_BASE_URL + path)
    url.searchParams.set('organisation', organisationId || '')
    const res: Promise<FetchResponse<T>> = fetch(`${url.toString()}`, {
      method: 'GET',
      ...init,
    })
    return res
  }

  const value: useOrgProps = {
    orgId,
    org,
    role,
    orgFetch,
  }

  return (
    <OrgContext.Provider value={value}>
      {router.asPath !== '/login' && loading ? (
        <ConnectingOrg />
      ) : (
        <>
          {children}
          {currentOrg?.orgType == 'DEMO' && <OrgBadge orgType={'Demo'} />}
          {currentOrg?.orgType == 'TEST' && <OrgBadge orgType={'Test'} />}
          {currentOrg?.orgType == 'INTERNAL' && <OrgBadge orgType={'Internal'} />}
        </>
      )}
    </OrgContext.Provider>
  )
}

function OrgBadge({ orgType }: { orgType: string }) {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        backgroundColor: 'var(--accent-clr)',
        padding: '.7rem 1.5rem',
        color: 'white',
        borderRadius: 'var(--border-radius-small) 0 0 0',
        cursor: 'default',
        fontWeight: '700',
      }}
    >
      {orgType} Org
    </div>
  )
}

function ConnectingOrg() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'var(--primary-bg-clr)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
      }}
    >
      <BarLoader loading={true} color="#EF1E6E" speedMultiplier={1.5} cssOverride={{ borderRadius: '100px' }} />
      connecting to organization
    </div>
  )
}
