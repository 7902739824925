import { useEffect, useState } from 'react'

function getLocalStoreValue(key: string, initialValue: any) {
  if (typeof window === 'undefined') return initialValue
  const localStoredState = localStorage.getItem(key)
  if (localStoredState) {
    try {
      const persistedState = eval(localStoredState)
      return persistedState
    } catch (err) {}
    return localStoredState
  }
  return initialValue
}

export function useLocalStore<T>(key: string, initialValue: T | any = undefined): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(getLocalStoreValue(key, initialValue))

  const localStorageEvent = new Event('storage')

  const storageEventHandler = () => {
    const localStoredValue = getLocalStoreValue(key, state)
    if (localStoredValue != state) {
      setState(localStoredValue)
    }
  }

  useEffect(() => {
    if (typeof window === 'undefined') return
    localStorage.setItem(key, JSON.stringify(state))
    window.dispatchEvent(localStorageEvent)
  }, [state])

  useEffect(() => {
    window.addEventListener('storage', storageEventHandler)
    return () => {
      window.removeEventListener('storage', storageEventHandler)
    }
  }, [])

  return [state, setState]
}
