import { useEffect, useState } from 'react'

function getValue(name: string) {
  const url = new URL(window.location.href)
  const value = url.searchParams.get(name)
  if (value) {
    return decodeURIComponent(value)
  }
  return undefined
}

export function useParams(
  name: string,
  defaultState?: string,
  priority?: 'URL' | 'DEFAULT'
): [string | undefined, (value: string) => void, () => void] {
  const [state, updateState] = useState(priority == 'URL' ? getValue(name) || defaultState : defaultState || getValue(name))

  useEffect(() => {
    const url = new URL(window.location.href)
    const value = url.searchParams.get(name)
    if (value) {
      updateState(decodeURIComponent(value))
    }
    function setEventData(e: any) {
      updateState(decodeURIComponent(e?.detail?.data))
    }
    window.addEventListener(`queryParam-${name}`, setEventData)
    return () => {
      window.removeEventListener(`queryParam-${name}`, setEventData)
    }
  }, [])

  function setState(value: string) {
    const url = new URL(window.location.href)
    url.searchParams.set(name, encodeURIComponent(value))
    window.history.replaceState({}, '', url)
    updateState(value)
    const event = new CustomEvent(`queryParam-${name}`, { detail: { data: value } })
    window.dispatchEvent(event)
  }

  function removeParam() {
    const url = new URL(window.location.href)
    url.searchParams.delete(name)
    window.history.replaceState({}, '', url)
    updateState(undefined)
    const event = new CustomEvent(`queryParam-${name}`, { detail: { data: undefined } })
    window.dispatchEvent(event)
  }

  return [state, setState, removeParam]
}
