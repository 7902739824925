import React, { useContext, useEffect } from 'react'
import { useMiniContext, useParams } from '../hooks'
import type { Integration } from '../types/orgTypes'
import { useAuth0 } from '@auth0/auth0-react'
import { useOrg } from './OrgContext'

type MiniContext<T> = [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>, boolean, React.Dispatch<React.SetStateAction<boolean>>]

const IntegrationContext = React.createContext<useIntegrationProps>({
  integrations: [undefined, () => {}, true, () => {}],
  currentIntegrationMiniContext: [undefined, () => {}, true, () => {}],
  integrationFetch: () => new Promise((resolve, reject) => {}),
})

export function useIntegrationContext() {
  return useContext(IntegrationContext)
}

interface useIntegrationProps {
  integrations: MiniContext<Integration[]>
  currentIntegrationMiniContext: MiniContext<string | undefined>
  integrationFetch: <T>(path: string, init?: RequestInit | undefined) => Promise<FetchResponse<T>>
}

export function IntegrationProvider({ children }: { children: React.ReactNode }) {
  const integrations = useMiniContext<Integration[]>()
  const currentIntegrationMiniContext = useMiniContext<string>()
  const { getAccessTokenSilently } = useAuth0()
  const [currentIntegrationId, setCurrentIntegration] = currentIntegrationMiniContext
  const {
    orgId: [orgId],
  } = useOrg()

  const [integrationIdParam, setIntegrationIdParam] = useParams('integrationId', '', 'URL')

  useEffect(() => {
    if (integrationIdParam !== currentIntegrationId) {
      setCurrentIntegration(integrationIdParam)
    }
  }, [integrationIdParam])

  useEffect(() => {
    currentIntegrationId && setIntegrationIdParam(currentIntegrationId)
  }, [currentIntegrationId])

  async function integrationFetch<T = any>(path: string, init: RequestInit | undefined = {}): Promise<FetchResponse<T>> {
    const token = await getAccessTokenSilently()
    init.headers = {
      'Content-Type': 'application/json',
      ...init.headers,
      'Authorization': `Bearer: ${token}`,
    }
    const url = new URL(process.env.NEXT_PUBLIC_API_BASE_URL + path)
    url.searchParams.set('organisation', orgId || '')
    url.searchParams.set('integration', currentIntegrationId as string)
    return fetch(`${url.toString()}`, {
      method: 'GET',
      ...init,
    })
  }
  const value: useIntegrationProps = {
    integrations,
    currentIntegrationMiniContext,
    integrationFetch,
  }

  return <IntegrationContext.Provider value={value}>{children}</IntegrationContext.Provider>
}

interface FetchResponse<T> extends Response {
  json: () => Promise<T>
}
