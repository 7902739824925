import { useEffect, useState } from 'react'
import { FiHome, FiLogOut, FiSettings } from 'react-icons/fi'
import { IoChevronBackOutline, IoAnalyticsSharp } from 'react-icons/io5'
import { MdOutlineFeedback, MdQueryStats } from 'react-icons/md'
import { DropdownNavlink, Navlink, ProfileNavlink } from './Navlink'
import { useRouter } from 'next/router'
import { useScreen } from '../../hooks/useScreen'
import { useAuth, useOrg, useTheme } from '../../contexts'
import { Capitalize } from '../../utils'
import { RiQuestionnaireFill } from 'react-icons/ri'
import { IoIosVideocam } from 'react-icons/io'
import { TbChartArcs3 } from 'react-icons/tb'
import { useQueryClient } from '@tanstack/react-query'

export function Menu() {
  const { menuOpen, setMenuOpen } = useTheme()
  const { width } = useScreen()
  const {
    org: [org],
  } = useOrg()
  const { logout, user } = useAuth()

  return (
    <div className={`menu-wrapper ${menuOpen ? 'opened' : 'closed'}`}>
      <Logo />
      <ProfileNavlink
        active={window.location.pathname == '/profile'}
        title={Capitalize(user?.nickname || user?.nickname || '')}
        subtitle={user?.email || ''}
        profilePicture={user?.picture || ''}
        event="/profile"
      />
      <div className="navlinks">
        <Navlink active={window.location.pathname == '/'} text="Dashboard" icon={<FiHome fontSize={18} strokeWidth={2.5} />} event="/" />
        <Navlink
          active={/^\/analytics(.+)?/.test(window.location.pathname)}
          text="Analytics"
          icon={<IoAnalyticsSharp fontSize={18} />}
          event="/analytics"
        ></Navlink>
        <Navlink active={window.location.pathname == '/model'} text="Model Insights" icon={<MdQueryStats fontSize={18} />} event="/model" />
        <Navlink
          active={window.location.pathname == '/faq-generation'}
          text="Faq Generation"
          icon={<RiQuestionnaireFill fontSize={18} />}
          event=""
          disabled={true}
        ></Navlink>
        <Navlink text="ROI" icon={<TbChartArcs3 fontSize={18} />} event="" disabled={true}></Navlink>
        {}
      </div>

      <div className="helper-links">
        {/* <DropdownNavlink
          active={router.asPath == '/help-center'}
          open={router.asPath.includes('/help-center')}
          text="Helpcentrum"
          icon={<FiHelpCircle fontSize={18} strokeWidth={2.5} />}
          event={'/help-center'}
        >
          <DropdownNavlinkItem
            active={router.asPath == '/help-center/getting-started'}
            text="Aan de slag"
            icon={<IoRocket fontSize={12} strokeWidth={2.5} />}
            event="/help-center/getting-started"
            fontSize="14px"
          />
          <DropdownNavlinkItem
            active={router.asPath == '/help-center/faq'}
            text="FAQ"
            icon={<IoChatbubble fontSize={12} strokeWidth={2.5} />}
            event="/help-center/faq"
            fontSize="14px"
          />
        </DropdownNavlink> */}
        {org?.orgType == 'DEMO' && (
          <DropdownNavlink
            active={window.location.pathname == '/demos'}
            icon={<IoIosVideocam fontSize={19} />}
            text="Demos"
            event="/demos"
          ></DropdownNavlink>
        )}
        <DropdownNavlink
          active={window.location.pathname == '/feedback'}
          // open={router.asPath.includes('/help-center')}
          text="Feedback"
          icon={<MdOutlineFeedback fontSize={19} />}
          event={'/feedback'}
        ></DropdownNavlink>
      </div>

      <div className={`footerlinks ${menuOpen ? 'opened' : 'closed'}`}>
        <DropdownNavlink text="Log out" icon={<FiLogOut fontSize={15} strokeWidth={2.5} />} event={() => logout()} />
        <DropdownNavlink
          active={/^\/settings.+/.test(window.location.pathname)}
          text="Settings"
          icon={<FiSettings fontSize={15} strokeWidth={2.5} />}
          event={'/settings/general'}
        />
      </div>
      <div onClick={() => setMenuOpen(prev => !prev)} className={`nav-collapse-button ${menuOpen ? 'opened' : 'closed'}`}>
        <IoChevronBackOutline fontSize={16} color={width > 992 ? 'var(--accent-clr)' : 'var(--secondary-text-clr)'} />
        <IoChevronBackOutline fontSize={16} color={width > 992 ? 'var(--accent-clr)' : 'var(--secondary-text-clr)'} />
      </div>
    </div>
  )
}

function Logo() {
  const {
    org: [currentOrg],
  } = useOrg()
  const { colorScheme, menuOpen } = useTheme()
  const router = useRouter()
  const [imgSource, setImgSource] = useState(currentOrg?.logo || '/static/images/logo-no-text.png')
  const { width: screenWidth } = useScreen()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (currentOrg?.logo) {
      return setImgSource(currentOrg.logo)
    }
    setImgSource(
      menuOpen && screenWidth > 992 && colorScheme == 'light'
        ? '/static/images/logo-black.png'
        : menuOpen && screenWidth > 992
        ? '/static/images/logo-white.png'
        : '/static/images/logo-no-text.png'
    )
  }, [menuOpen, screenWidth, colorScheme, currentOrg])

  return (
    <div onClick={() => router.push('/')} className="logo">
      <img onError={() => queryClient.invalidateQueries(['current-org'])} src={imgSource} className="img" alt="logo" />
    </div>
  )
}
