import React, { useContext } from 'react'
import { useMiniContext } from '../hooks'
import type { ApiKey, FileUpload, Integration, Members } from '../types/orgTypes'

type MiniContext<T> = [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>, boolean, React.Dispatch<React.SetStateAction<boolean>>]

const DataContext = React.createContext<useDataProps>({
  members: [undefined, () => {}, true, () => {}],
  integrations: [undefined, () => {}, true, () => {}],
  apiKeys: [undefined, () => {}, true, () => {}],
  newestIntegrations: [undefined, () => {}, true, () => {}],
  uploads: [undefined, () => {}, true, () => {}],
  basicAnalytics: [undefined, () => {}, true, () => {}],
})

export function useData() {
  return useContext(DataContext)
}

interface useDataProps {
  members: MiniContext<Members>
  integrations: MiniContext<Integration[]>
  apiKeys: MiniContext<ApiKey[]>
  newestIntegrations: MiniContext<Integration[]>
  uploads: MiniContext<FileUpload[]>
  basicAnalytics: MiniContext<{ [x: string]: any }>
}

export function DataProvider({ children }: { children: React.ReactNode }) {
  const members = useMiniContext<Members>()
  const integrations = useMiniContext<Integration[]>()
  const apiKeys = useMiniContext<ApiKey[]>()
  const newestIntegrations = useMiniContext<Integration[]>([])
  const uploads = useMiniContext<FileUpload[]>([])
  const basicAnalytics = useMiniContext<{ [x: string]: any }>({})

  const value: useDataProps = {
    members,
    integrations,
    apiKeys,
    newestIntegrations,
    uploads,
    basicAnalytics,
  }

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}
