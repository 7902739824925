import { useEffect, useRef, useState } from 'react'

function getLocalStoredValue<L>(key: string): L | undefined {
  const localStoredState = localStorage.getItem(key)
  if (localStoredState) {
    try {
      const persistedState = JSON.parse(localStoredState)
      return persistedState
    } catch (err) {
      try {
        const persistedState = eval(localStoredState)
        return persistedState
      } catch (err) {
        return undefined
      }
    }
    // @ts-ignore
    return localStoredState
  }
  return undefined
}

export function useStoredFetch<T>(
  key: string,
  callback: (signal: AbortSignal) => any,
  dependencies: any[] = [],
  timeout: number = 600000
): { loading: boolean; error: any; data: T | undefined; forceUpdate: (data: T) => any; callback: (signal: AbortSignal) => any } {
  const firstRender = useRef(true)
  const [data, setData] = useState<T | undefined>(getLocalStoredValue(key))
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<any>(undefined)

  async function forceUpdate(freshData: T) {
    setData(freshData)
    localStorage.setItem(key, JSON.stringify(freshData))
    setLoading(false)
  }

  async function makeFetchRequest(signal: AbortSignal) {
    try {
      const callBackData = await callback(signal)
      if (callBackData) {
        setData(JSON.parse(JSON.stringify(callBackData)))
        localStorage.setItem(key, JSON.stringify(callBackData))
      }
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!firstRender) return
    firstRender.current = false
    const stored = getLocalStoredValue(key)
    if (stored) {
      // @ts-ignore
      setData(JSON.parse(JSON.stringify(stored)))
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal
    let interval: NodeJS.Timer
    ;(async () => {
      await makeFetchRequest(signal)

      interval = setInterval(
        async () => {
          await makeFetchRequest(signal)
        },
        timeout == 0 ? 10000 : timeout
      )

      if (timeout == 0) {
        clearInterval(interval)
      }
    })()

    return () => {
      clearInterval(interval)
      abortController.abort()
    }
  }, [...dependencies])

  return { loading, error, data, forceUpdate, callback }
}
