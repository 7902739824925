import React, { useState } from 'react'

export function useMiniContext<T>(
  initialValue: T | undefined = undefined
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>, boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [state, setState] = useState<T | undefined>(initialValue)
  const [loading, setLoading] = useState(true)

  return [state, setState, loading, setLoading]
}
